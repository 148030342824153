import { Injectable } from '@angular/core';
import { DateSpan } from 'app/project/project-list/project-list.service';
import { first, Observable, Subject } from 'rxjs';
import {
  GetProjectDataWithKpisGQL,
  ProjectLaborCostByTypeFragment,
  ProjectLaborCostByUserFragment,
  ProjectMileCostFragment,
  ProjectProductCostFragment,
} from './graphql/project-overview.generated';

const CALCULATION_MODE_KEY = 'projectCostCalculation';
type ProjectCosts = {
  laborCostsByUser?: ProjectLaborCostByUserFragment;
  laborCostsByType?: ProjectLaborCostByTypeFragment;
  mileCosts?: ProjectMileCostFragment;
  productCosts?: ProjectProductCostFragment;
};

@Injectable()
export class ProjectOverviewService {
  public projectOverviewData = new Subject<ProjectOverviewData>();
  private currentCostCalculationMode: CostCalculationMode;
  private loadedData: {
    projectId: number;
    kpis: ProjectKpiCollection;
    costs: ProjectCosts;
  };

  constructor(private getProjectDataWithKpis: GetProjectDataWithKpisGQL) {}

  public get costCalculationMode(): CostCalculationMode {
    if (this.currentCostCalculationMode === undefined) {
      const value = window.localStorage.getItem(CALCULATION_MODE_KEY);
      this.currentCostCalculationMode =
        value !== null
          ? CostCalculationMode[value]
          : CostCalculationMode.ByUser;
    }
    return this.currentCostCalculationMode;
  }
  public set costCalculationMode(value: CostCalculationMode) {
    window.localStorage.setItem(
      CALCULATION_MODE_KEY,
      CostCalculationMode[value]
    );
    this.currentCostCalculationMode = value;
    this.calculateOverviewData();
  }

  public fetchOverviewData(
    projectId: number,
    timeSpan: DateSpan = null,
    includeSubProjects = false
  ): Observable<ProjectOverviewData> {
    this.getProjectDataWithKpis
      .fetch({
        projectId: projectId,
        fromDate: timeSpan?.startDate?.toLocaleDateString(`sv-SE`) ?? null,
        toDate: timeSpan?.endDate?.toLocaleDateString(`sv-SE`) ?? null,
        includeSubProjects: includeSubProjects,
      })
      .pipe(first())
      .subscribe(result => {
        const costData = result.data.company.projects.edges.find(
          () => true
        ).node;

        const kpiData = result.data.company.projectsWithKpis.edges.find(
          () => true
        )?.node.kpis;
        if (!kpiData) {
          return;
        }
        const kpiCollection: ProjectKpiCollection = {
          calculationMode: this.costCalculationMode,
        };
        Object.entries(kpiData)
          .filter(x => x[0] !== '__typename')
          .map(x => [x[0], Number(x[1])])
          .forEach(x => (kpiCollection[x[0]] = x[1]));
        this.loadedData = {
          projectId: projectId,
          kpis: kpiCollection,
          costs: costData,
        };
        this.calculateOverviewData();
      });

    return this.projectOverviewData;
  }

  public refetchInvoiceData(): void {
    this.fetchOverviewData(this.loadedData.projectId);
  }

  private calculateOverviewData(): void {
    this.loadedData.kpis = {
      ...this.loadedData.kpis,
      calculationMode: this.costCalculationMode,
    };

    const overviewData = this.parseLoadedData();
    this.projectOverviewData.next(overviewData);
  }

  public changeCalculationMode(newMode: CostCalculationMode): void {
    this.costCalculationMode = newMode;
    this.calculateOverviewData();
  }

  private parseLoadedData(): ProjectOverviewData {
    const { kpis: kpiData, projectId } = this.loadedData;
    const economyData = this.parseEconomyData(kpiData);
    const data: ProjectOverviewData = {
      projectId,
      economyData: economyData,
      kpis: this.loadedData.kpis,
    };
    return data;
  }

  public parseEconomyData(kpiData: ProjectKpiCollection): EconomyData {
    const overhead = kpiData.costsOverheadAmount;
    return {
      costs: {
        labor: {
          overhead: overhead,
          totalMileCost: kpiData.costsMilesAmount,
          byUser: {
            total: kpiData.costsSalaryBasedWorkAmount,
            users: this.loadedData.costs.laborCostsByUser?.users,
          },
          byLaborType: {
            total: kpiData.costsUserCostTypesBasedWorkAmount,
            users: this.loadedData.costs.laborCostsByType?.users,
            types: this.loadedData.costs.laborCostsByType?.types,
          },
          mileCosts: {
            total: kpiData.costsMilesAmount,
            users: this.loadedData.costs.mileCosts.users,
            byExtraNormal: this.loadedData.costs.mileCosts.byExtraNormal,
          },
        },
        material: this.loadedData.costs.productCosts,
      },
    };
  }
}

export interface ProjectOverviewData {
  projectId: number;
  economyData?: EconomyData;
  kpis?: ProjectKpiCollection;
}

export interface EconomyData {
  costs: {
    labor: LaborCosts;
    material: ProjectProductCostFragment;
  };
}

export interface LaborCosts {
  overhead: number;
  totalMileCost: number;
  byUser: ProjectLaborCostByUserFragment;
  byLaborType: ProjectLaborCostByTypeFragment;
  mileCosts: ProjectMileCostFragment;
}

export interface ProjectKpiCollection {
  calculationMode: CostCalculationMode;
  invoicesTotalAmount?: number;
  invoicesWorkAmount?: number;
  invoicesMaterialAmount?: number;
  invoicesMilesAmount?: number;
  revenuesATAAcceptedAmount?: number;
  revenuesATAInvoicedAmount?: number;
  revenuesOfferedAmount?: number;
  revenuesTotalAmount?: number;
  revenuesWorkAmount?: number;
  revenuesProductsAmount?: number;
  revenuesMilesAmount?: number;
  revenuesWorkExtraAmount?: number;
  revenuesMaterialExtraAmount?: number;
  revenuesMilesExtraAmount?: number;
  costsSalaryBasedAmount?: number;
  costsUserCostTypesBasedAmount?: number;
  costsSalaryBasedWorkAmount?: number;
  costsUserCostTypesBasedWorkAmount?: number;
  costsProductsAmount?: number;
  costsMilesAmount?: number;
  costsOverheadAmount?: number;
  hoursSum?: number;
  hoursExtraSum?: number;
  milesSum?: number;
  milesExtraSum?: number;
  invoicesResultsSalaryBasedAmount?: number;
  invoicesResultsUserCostTypesBasedAmount?: number;
  resultsExpectedSalaryBasedAmount?: number;
  resultsExpectedUserCostTypesBasedAmount?: number;
  resultsCoverageInvoicedSalaryBasedPercentage?: number;
  resultsCoverageInvoicedUserCostTypesBasedPercentage?: number;
  resultsCoverageExpectedOfferedSalaryBasedPercentage?: number;
  resultsCoverageExpectedOfferedUserCostTypesBasedPercentage?: number;
}

export enum CostCalculationMode {
  ByUser,
  ByCostType,
}
